import { parseUrlParams } from "../../../core/library/url";

const PROJECT = process.env.PROJECT || "";
const ExcludeEmails = ['d.bocharova@nationalpriority.ru', 'e.sahno@nationalpriority.ru'];

export const production = {
  ExcludeEmails,
  SHOW_LANGS: true,
  APP_CLASS: "staging",
  PROJECT_NAME: "RT-School",
  PROJECT_PREFIX: "rts",
  LANGS: [{ title: "Russian", domain: "https://admin-school.rt.com" }],
  PROJECT: {
    // project name
    NAME: PROJECT,
    // project default layout
    LAYOUT: "default",
    // project api path
    API: `https://admin-school.rt.com/api/v1`,
  },
  OAUTH: {
    // url for oauth domain
    URL: `https://auth.rt.com`,
    // current project domain
    PROJECT_DOMAIN: `admin-school.rt.com`,
    // current project url path
    PROJECT_PATH: `https://admin-school.rt.com`,
    // path for oauth api
    API: `https://auth.rt.com/auth`,
    // path for oauth users
    OAUTH_USER_PATH: `https://auth.rt.com/users/`,
    // prefix for authorization tokens
    PREFIX: "oauth_",
    AUTH_HEADER: "X-Access-Token",
  },
  VIEWS: {},
  MENU_NAUCH: [{
    title: "HOMEWORKS_LIST",
    path: "/",
    component: "homeworks_controller",
    icon: "file-search",
    subroutes: [
      {
        path: `/homeworks/:id`,
        component: "homework_controller",
      },
    ],
  },],
  MENU: [
    {
      title: "DASHBOARD",
      path: "/",
      component: "dashboard",
      icon: "dashboard",
      exclude: ExcludeEmails
    },
    {
      title: "Profiles",
      path: "/profiles",
      component: "profiles_controller",
      icon: "user",
      exclude: ExcludeEmails,
      subroutes: [
        {
          path: "/profiles/:id",
          component: "profile_edit_controller",
        },
      ],
    },
    {
      title: "SUBMITS_LIST",
      path: "/submits",
      component: "submits_controller",
      icon: "mail",
      exclude: ExcludeEmails,
      subroutes: [
        {
          path: "/submits/:id",
          component: "submits_single_controller",
        },
      ],
    },
    {
      title: "COURSES_LIST",
      path: "/courses",
      component: "courses_controller",
      icon: "number",
      exclude: ExcludeEmails,
      subroutes: [
        {
          path: "/courses/add",
          component: "courses_add",
        },
        {
          path: "/courses/:id",
          component: "courses_add",
        },
        {
          path: "/courses/:id/preview",
          component: "course_preview",
        },
      ],
    },
    {
      title: "STAGES_LIST",
      path: "/stages",
      component: "stages_controller",
      icon: "unordered-list",
      exclude: ExcludeEmails,
      subroutes: [
        {
          path: "/stages/add",
          component: "stage_add",
        },
        {
          path: "/stages/:id",
          component: "stage_add",
        },
        {
          path: "/stages/:id/preview",
          component: "stage_preview",
        },
      ],
    },
    {
      title: "LESSONS_LIST",
      path: "/lessons",
      component: "lessons_controller",
      icon: "experiment",
      exclude: ExcludeEmails,
      subroutes: [
        {
          path: "/lessons/add",
          component: "lesson_add",
        },
        {
          path: "/lessons/:id",
          component: "lesson_add",
        },
        {
          path: "/lessons/:id/preview",
          component: "lesson_preview",
        },
      ],
    },
    {
      title: "TEACHERS_LIST",
      path: "/teachers",
      component: "teachers_controller",
      icon: "crown",
      exclude: ExcludeEmails,
      subroutes: [
        {
          path: "/teachers/add",
          component: "teacher_add",
        },
        {
          path: "/teachers/:id",
          component: "teacher_add",
        },
      ],
    },
    {
      title: "HOMEWORKS_LIST",
      path: "/homeworks",
      component: "homeworks_controller",
      icon: "file-search",
      subroutes: [
        {
          path: `/homeworks/migration`,
          component: "HomeworksMigrations",
        },
        {
          path: `/homeworks/:id`,
          component: "homework_controller",
        },
      ],
    },
    {
      title: "MEDIA",
      path: "/media",
      component: "media_controller",
      icon: "file-image",
      exclude: ExcludeEmails
    },
    {
      title: "NOTIFICATION_LIST",
      path: "/notifications",
      component: "notification",
      icon: "notification",
      exclude: ExcludeEmails
    },
    {
      title: "WEBINARS_LIST",
      path: "/webinars",
      component: "webinars_controller",
      icon: "youtube",
      exclude: ExcludeEmails,
      submenu: [
        {
          title: "WEBINARS_CONTROL",
          path: "/webinars",
          component: "webinars_controller",
        },
        {
          title: "WEBINARS_SUBSCRIBERS",
          path: "/webinars/subscribers",
          component: "webinar_subscribers",
        },
      ],
      subroutes: [
        {
          path: "/webinars/add",
          component: "webinar_add",
        },
        {
          path: "/webinars/:id",
          component: "webinar_add",
        },
      ],
    },
    {
      title: "MAILING",
      icon: "bell",
      path: "/mailing",
      exclude: ExcludeEmails,
      submenu: [
        {
          title: "PREREQUESTS_LIST",
          path: "/mailing/prerequests",
          component: "preRequests_controller",
        },
        {
          title: "STAGES_MAILING",
          path: "/mailing/stages",
          component: "mailing_controller",
        },
        {
          title: "WEBINARS_MAILING",
          path: "/mailing/webinars",
          component: "webinar_notification",
        },
      ],
    },
  ],
  MEDIA: {
    // path for cdn content
    CDN: {
      IMAGES: "https://cdn.rt-school.online/rt_school/images",
      VIDEO: "https://cdn.rt-school.online/rt_school/videos",
      AUDIO: "https://cdn.rt-school.online/rt_school/audio",
      AVATARS: "https://cdn.rt-school.online/rt_school/avatars/",
      DOCUMENTS: "https://cdn.rt-school.online/rt_school/documents",
      ATTACH: "https://cdn.rt-school.online/rt_school/docs",
    },
    EMBEDDED_SOURCES: [
      "youtube.com",
      "facebook.com",
      "youtu.be",
      "rutube.ru",
      "vk.com",
    ],
    EMEDDED_PREPARE: {
      "youtu.be": (path) => {
        let params = path.split("/");
        return `<iframe width="560" height="315" src="https://www.youtube.com/embed/${
          params[params.length - 1]
        }" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`;
      },
      "youtube.com": (path) => {
        let p = parseUrlParams(path);
        if (!p.v) return ``;
        return `<iframe width="560" height="315" src="https://www.youtube.com/embed/${p.v}" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`;
      },
      "facebook.com": (url) => {
        return `<iframe src="https://www.facebook.com/plugins/video.php?href=${encodeURIComponent(
          url
        )}&width=500&show_text=false&appId=526970354336335&height=280" width="500" height="280" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowTransparency="true" allow="encrypted-media" allowFullScreen="true"></iframe>`;
      },
      "vk.com": (url) => {
        let oid = url.match(/-\d{8}/)[0];
        let id = url.match(/\d{9}/)[0];
        return `<iframe src="https://vk.com/video_ext.php?oid=${oid}&id=${id}&hd=2" width="560" height="315" allow="autoplay; encrypted-media; fullscreen; picture-in-picture;" frameborder="0" allowfullscreen></iframe>`;
      },
    },
  },
  UPLOAD: {
    path: "https://admin-school.rt.com/api/v1/media",
  },
};
