// import MediaSources from "../../media_sources";
import { parseUrlParams } from "../../../core/library/url";

// const PROJECT = process.env.PROJECT || "";

export const dev = {
  SHOW_LANGS: true,
  APP_CLASS: "dev",
  PROJECT_NAME: "RT-School",
  PROJECT_PREFIX: "rts",
  LANGS: [{ title: "Russian", domain: "http://ru.localhost:3000" }],

  VIEWS: {},
  MENU: [
    {
      title: "DASHBOARD",
      path: "/",
      component: "dashboard",
      icon: "dashboard",
    },
    {
      title: "Profiles",
      path: "/profiles",
      icon: "user",
      submenu: [
        {
          title: "Students",
          path: "/profiles",
          component: "profiles_controller",
          icon: "user",
        },
        {
          title: "TEACHERS_LIST",
          path: "/teachers",
          component: "teachers_controller",
          icon: "user",
        },
      ],
      subroutes: [
        {
          path: "/profiles/:id",
          component: "profile_edit_controller",
        },
        {
          path: "/teachers/add",
          component: "teacher_add",
        },
        {
          path: "/teachers/:id",
          component: "teacher_add",
        },
      ],
    },
    {
      title: "SUBMITS_LIST",
      path: "/submits",
      component: "submits_controller",
      icon: "mail",
      subroutes: [
        {
          path: "/submits/:id",
          component: "submits_single_controller",
        },
      ],
    },
    {
      title: "COURSES_LIST",
      path: "/courses",
      component: "courses_controller",
      icon: "number",
      subroutes: [
        {
          path: "/courses/add",
          component: "courses_add",
        },
        {
          path: "/courses/:id",
          component: "courses_add",
        },
        {
          path: "/courses/:id/preview",
          component: "course_preview",
        },
      ],
    },
    {
      title: "STAGES_LIST",
      path: "/stages",
      component: "stages_controller",
      icon: "flag",
      subroutes: [
        {
          path: "/stages/:id",
          component: "stage_add",
        },
        {
          path: "/stages/:id/preview",
          component: "stage_preview",
        },
      ],
    },
    {
      title: "LESSONS_LIST",
      path: "/lessons",
      component: "lessons_controller",
      icon: "experiment",
      subroutes: [
        {
          path: "/lessons/add",
          component: "lesson_add",
        },
        {
          path: "/lessons/:id",
          component: "lesson_add",
        },
        {
          path: "/lessons/:id/preview",
          component: "lesson_preview",
        },
      ],
    },
    {
      title: "TEACHERS_LIST",
      path: "/teachers",
      component: "teachers_controller",
      icon: "crown",
      subroutes: [
        {
          path: "/teachers/add",
          component: "teacher_add",
        },
        {
          path: "/teachers/:id",
          component: "teacher_add",
        },
      ],
    },
    {
      title: "HOMEWORKS_LIST",
      path: "/homeworks",
      component: "homeworks_controller",
      icon: "file-search",
      subroutes: [
        {
          path: `/homeworks/migration`,
          component: "HomeworksMigrations",
        },
        {
          path: `/homeworks/:id`,
          component: "homework_controller",
        },
      ],
    },
    {
      title: "MEDIA",
      path: "/media",
      component: "media_controller",
      icon: "file-image",
    },
    {
      title: "NOTIFICATION_LIST",
      path: "/notifications",
      component: "notification",
      icon: "notification",
    },
    {
      title: "WEBINARS_LIST",
      path: "/webinars",
      component: "webinars_controller",
      icon: "youtube",
      submenu: [
        {
          title: "WEBINARS_CONTROL",
          path: "/webinars",
          component: "webinars_controller",
        },
        {
          title: "WEBINARS_SUBSCRIBERS",
          path: "/webinars/subscribers",
          component: "webinar_subscribers",
        },
      ],
      subroutes: [
        {
          path: "/webinars/add",
          component: "webinar_add",
        },
        {
          path: "/webinars/:id",
          component: "webinar_add",
        },
      ],
    },
    {
      title: "MAILING",
      icon: "bell",
      path: "/mailing",
      submenu: [
        {
          title: "PREREQUESTS_LIST",
          path: "/mailing/prerequests",
          component: "preRequests_controller",
        },
        {
          title: "STAGES_MAILING",
          path: "/mailing/stages",
          component: "mailing_controller",
        },
        {
          title: "WEBINARS_MAILING",
          path: "/mailing/webinars",
          component: "webinar_notification",
        },
      ],
    },
  ],
  UPLOAD: {
    path: "http://localhost:9000/api/v1/media",
  },
  EMBEDDED_SOURCES: [
    "youtube.com",
    "facebook.com",
    "youtu.be",
    "rutube.ru",
    "vk.com",
  ],
  EMEDDED_PREPARE: {
    "youtu.be": (path) => {
      let params = path.split("/");
      return `<iframe width="560" height="315" src="https://www.youtube.com/embed/${
        params[params.length - 1]
      }" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`;
    },
    "youtube.com": (path) => {
      let p = parseUrlParams(path);
      if (!p.v) return ``;
      return `<iframe width="560" height="315" src="https://www.youtube.com/embed/${p.v}" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`;
    },
    "facebook.com": (url) => {
      return `<iframe src="https://www.facebook.com/plugins/video.php?href=${encodeURIComponent(
        url
      )}&width=500&show_text=false&appId=526970354336335&height=280" width="500" height="280" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowTransparency="true" allow="encrypted-media" allowFullScreen="true"></iframe>`;
    },
    "vk.com": (url) => {
      let oid = url.match(/-\d{8}/)[0];
      let id = url.match(/\d{9}/)[0];
      return `<iframe src="https://vk.com/video_ext.php?oid=${oid}&id=${id}&hd=2" width="560" height="315" allow="autoplay; encrypted-media; fullscreen; picture-in-picture;" frameborder="0" allowfullscreen></iframe>`;
    },
  },
};
